import { useContext, useEffect, useState } from "react";
import { IMainContext } from "../interface/Interface";
import { MainContext } from "../common/Context";
import { NavLink, useParams, useHistory, useLocation } from "react-router-dom";
import { timeFormat11 } from "../common/Method";
import down from "../images/down.png";
import "../styles/tailwindCustom.css"; // tailwind 스타일 적용하려는 페이지는 해당 소스파일을 import 하면 된다  - 24.11.19
import OutsideClickHandler from "react-outside-click-handler";
import { getMeyoList, postDeleteMeyo } from "../common/Action";
import { TYPE_TEXT } from "../common/MeUtils";

{
  /* 저요 24.11.19 added */
  // 저요 클릭하면 나오는 메인 페이지
}
const Me: React.FC = () => {
  const context = useContext<IMainContext>(MainContext);
  const params: { classId: string } = useParams();
  const history = useHistory();
  const location = useLocation(); // MeTextType 에서 저요 개별데이터가 업데이트 되면 init 함수에서  dataAllQuestions의 상태를 업데이트 하기위해 필요

  const [typeList, setTypeList] = useState<string[]>([]);
  const [popupSortFilter, setPopupSortFilter] = useState<boolean>(false);
  const [filterSort, setFilterSort] = useState<string[]>([]);
  const [filterType, setFilterType] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [currentSortFilter, setCurrentSortFilter] = useState<string>("최신순");
  const [dataAllQuestions, setDataAllQuestions] = useState<any>([]);
  const [menuChocolateChips, setMenuChocolateChips] = useState<any>();
  const [selectedItems, setSelectedItems] = useState<string[]>([]); // 삭제 예정으로 체크된 저요 아이템 관리
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 15; // 페이지당 표시할 항목 수

  useEffect(() => {
    init();
  }, [location.pathname]);

  const init = async (sort: number = -1): Promise<void> => {
    const { result }: any = await getMeyoList(sort, params.classId);
    // console.log("getMeyoList api call", result);
    // console.log("학급 ID", params.classId);

    setTypeList(["텍스트형", "선택형", "그림판형"]);
    setFilterType(["텍스트형", "선택형", "그림판형"]);
    setFilterSort(["최신순", "오래된 순"]);

    if (!result) {
      setDataAllQuestions([]);
      return;
    }

    setDataAllQuestions(result);
  };

  // handler
  const handleSelectFilterType = (filterType: string) => {
    if (selectedFilters.includes(filterType)) {
      // 이미 선택된 필터를 다시 클릭하면 선택 해제
      setSelectedFilters(selectedFilters.filter((item) => item !== filterType));
    } else {
      // 새로운 필터를 선택하면 추가
      setSelectedFilters([...selectedFilters, filterType]);
    }
  };

  const filteredQuestions =
    selectedFilters.length > 0
      ? dataAllQuestions.filter((item: any) => selectedFilters.includes(item.type))
      : dataAllQuestions;

  // 현재 페이지에 해당하는 데이터만 필터링
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredQuestions.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredQuestions.length / itemsPerPage);

  // 페이지 변경 핸들러
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // 최신순 오래된 순 정렬 sort handler 프론트 정렬
  const handleSortFilterSelect = (index: number) => {
    init(filterSort[index] === "최신순" ? -1 : 1);
    setCurrentSortFilter(filterSort[index]);

    setPopupSortFilter(false);
  };

  // 저요 삭제하기 delete handler : 리스트에서 선택된 저요 데이터를 삭제하는 API
  const handleDeleteQuestion = async () => {
    const copy = [...dataAllQuestions];
    //   const deleteIds: any = copy.filter((item: any) => !selectedItems.includes(item._id));

    const { result }: any = await postDeleteMeyo({
      ids: selectedItems,
    });

    // console.log("삭제 결과", result);
    if (result) {
      setSelectedItems([]); // 선택된 항목 초기화
      init();
    }
  };

  // 저요 타입별 이모지
  const Emoji = (type: string) => {
    if (type === "텍스트형") {
      return "⌨️";
    } else if (type === "선택형") {
      return "☑️";
    } else if (type === "그림판형") {
      return "✍️";
    }
  };

  // 타입별로 다른 동작을 하도록 수정
  const handleTypeClick = (type: string) => {
    if (type === TYPE_TEXT) {
      history.push(`/page/metab/${params.classId}/metexttype`);
    } else {
      alert(`${type} 준비중입니다.`);
    }
  };

  // 질문 클릭 핸들러 추가
  const handleQuestionClick = (item: any) => {
    history.push(`/page/metab/${params.classId}/metexttype/${item._id}`, { questionData: item });
  };

  // 리스트 전체 선택 토글
  const toggleSelectAll = () => {
    const currentItemIds = currentItems.map((item: any) => item._id);
    const allSelected = currentItems.every((item: any) => selectedItems.includes(item._id));

    if (allSelected) {
      // 현재 페이지의 항목들을 선택 해제
      setSelectedItems(selectedItems.filter((id: string) => !currentItemIds.includes(id)));
    } else {
      // 현재 페이지의 항목들을 선택에 추가
      const newSelectedItems = [...selectedItems];
      currentItemIds.forEach((id: string) => {
        if (!newSelectedItems.includes(id)) {
          newSelectedItems.push(id);
        }
      });
      setSelectedItems(newSelectedItems);
    }
  };

  // 리스트 개별 아이템 선택 토글
  const toggleSelectItem = (id: string) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((itemId: string) => itemId !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  return (
    <div className={"tailwind-styles"}>
      <div className="justify-center items-center">
        <div className="mt-40 my-20 display-f align-center justify-between" style={{ width: 528 }}>
          <p className="text-4xl font-medium">저요</p>
          {/* <button>초코칩 관리</button> */}
        </div>

        <div className="flex justify-center space-x-10">
          {/* 타입 메뉴 텍스트형 선택형 그림판형 */}
          {typeList.map((type: string, index: number) => (
            <div
              key={index}
              onClick={() => handleTypeClick(type)}
              className={`bg-[#A4C49D] border border-4 border-[#539669] w-[390px] h-[200px] flex justify-center items-center cursor-pointer hover:bg-[#8fb587] transition-colors duration-200`}
              style={{ borderRadius: "20px" }}
            >
              <div className={"text-8xl font-bold text-[#347a4e] hover:text-[#1d5832] transition-colors duration-200"}>
                {`${Emoji(type)} ${type}`}
              </div>
            </div>
          ))}
        </div>
        {/* 타입 메뉴 */}

        {/* 필터 & 정렬 */}
        <OutsideClickHandler
          onOutsideClick={() => {
            setPopupSortFilter(false);
          }}
        >
          <div className="flex flex-row justify-between items-center mt-20 mb-10 space-x-6 p-4">
            {/* 선택 항목 삭제 버튼 */}
            <div>
              <button
                onClick={() => {
                  if (selectedItems.length === 0) {
                    alert("삭제할 항목을 선택해주세요.");
                    return;
                  }
                  const isConfirmed = window.confirm(`선택한 ${selectedItems.length}개의 항목을 삭제하시겠습니까?`);
                  if (isConfirmed) {
                    handleDeleteQuestion();
                  }
                }}
                className={`px-6 py-3 rounded-lg text-white font-medium w-40 h-16
                ${selectedItems.length > 0 ? "bg-[#d62d20] hover:bg-red-600" : "bg-gray-400 cursor-not-allowed"}
              `}
                disabled={selectedItems.length === 0}
              >
                {selectedItems.length > 0 ? `${selectedItems.length}개 삭제` : "삭제"}
              </button>
            </div>

            {/* 오른쪽 필터/정렬 영역 */}
            <div className="flex flex-row items-center space-x-6">
              {/* 리스트 필터 */}
              {filterType.map((filter: string, index: number) => (
                <div
                  key={index}
                  className={`${
                    selectedFilters.includes(filter) ? "bg-[#539669]" : "bg-white-100"
                  } w-40 h-16 flex justify-center items-center rounded-lg outline outline-2`}
                  style={{
                    outlineColor: "#347a4e",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    handleSelectFilterType(filter);
                  }}
                >
                  <p className="cursor" style={{ color: selectedFilters.includes(filter) ? "#fff" : "#347a4e" }}>
                    {filter}
                  </p>
                </div>
              ))}

              {/* 리스트 정렬 최신순 오래된순 */}
              <button
                onClick={() => setPopupSortFilter((prev) => !prev)}
                className="filter_btn text-left pl-17 pr-12 display-f justify-between align-center"
                style={{
                  opacity: 100,
                  transition: "all 0.3s ease-in-out", // 부드러운 전환 효과
                }}
              >
                {currentSortFilter} <img className="filter_icon" src={down} alt="down" />
              </button>

              <div className="relative">
                {popupSortFilter === true && (
                  <div className="absolute filter_contents bg-white" style={{ top: 22, right: 18 }}>
                    {filterSort.map((data: string, index: number) => (
                      <div onClick={() => handleSortFilterSelect(index)} className="filter_inner cursor" key={index}>
                        <p className="pl-17 pt-11 pb-11 font-14">{data}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* 정렬 */}
            </div>
          </div>
        </OutsideClickHandler>

        {/* 필터 & 정렬 */}

        {/* 저요 리스트 */}
        <table className="min-w-full border border-gray-300">
          <thead>
            <tr className="bg-[#A4C49D]">
              <th className="w-[50px] px-4 py-2 border-y border-x border-gray-300  text-center">
                <input
                  type="checkbox"
                  className="w-6 h-6 cursor-pointer accent-[#539669]"
                  checked={
                    currentItems.length > 0 && currentItems.every((item: any) => selectedItems.includes(item._id))
                  }
                  onChange={toggleSelectAll}
                />
              </th>
              <th className="w-[120px] px-4 py-2 border-y border-x border-gray-300  text-center">생성 날짜</th>
              <th className="w-[60%] px-4 py-2 border-y border-x border-gray-300  text-center">질문 내용</th>
              <th className="w-[120px] px-4 py-2 border-y border-x border-gray-300  text-center">유형</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item: any, index: number) => (
              <tr
                key={index}
                className="hover:bg-gray-50 cursor-pointer"
                onClick={() => {
                  if (item.type === TYPE_TEXT) {
                    // history.push(`/metab/${params.classId}/metexttype/${item.id}`);
                    handleQuestionClick(item);
                  }
                }}
              >
                <td className="px-4 py-2 border-b border-gray-300 text-center" onClick={(e) => e.stopPropagation()}>
                  <input
                    type="checkbox"
                    className="w-6 h-6 cursor-pointer accent-[#539669]"
                    checked={selectedItems.includes(item._id)}
                    onChange={() => toggleSelectItem(item._id)}
                  />
                </td>
                <td className="px-4 py-2  border-y border-x border-gray-300 text-center">
                  {timeFormat11(item.createdAt)}
                </td>
                <td className="px-4 py-2  border-y border-x border-gray-300 text-center">{item.question}</td>
                <td className="px-4 py-2  border-y border-x border-gray-300 text-center">{item.type}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* 페이지네이션 TODO:: 서버에서 페이지네이션 하도록 처리해야함 현재는 프론트에서만 처리중 */}
        {totalPages > 1 && (
          <div className="flex justify-center items-center mt-[20px] mb-10">
            {/* 체크박스 너비 */}
            <div className={"w-[50px]"} />
            {/* 생성 날짜 너비 */}
            <div className={"w-[120px]"} />
            {/* 질문 내용 너비와 동일한 비율로 페이지네이션 배치 */}
            <div className="w-[60%] flex justify-center items-center space-x-2">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`px-3 py-1 rounded ${
                  currentPage === 1 ? "bg-gray-200 text-gray-400" : "bg-[#539669] text-white hover:bg-[#347a4e]"
                }`}
              >
                이전
              </button>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
                <button
                  key={number}
                  onClick={() => handlePageChange(number)}
                  className={`px-3 py-1 rounded ${
                    currentPage === number
                      ? "bg-[#539669] text-white"
                      : "bg-white text-[#539669] border border-[#539669] hover:bg-[#f0f0f0]"
                  }`}
                >
                  {number}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={`px-3 py-1 rounded ${
                  currentPage === totalPages ? "bg-gray-300" : "bg-[#539669] text-white hover:bg-[#347a4e]"
                }`}
              >
                다음
              </button>
            </div>
            {/* 유형 너비 */}
            <div className={"w-[120px]"} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Me;
