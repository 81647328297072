import { useParams, useHistory } from "react-router-dom";
import "../styles/tailwindCustom.css"; // tailwind 스타일 적용하려는 페이지는 해당 소스파일을 import 하면 된다  - 24.11.19
import "../styles/Me.css"; // Me 스타일 추가
import { getMeyoDetail, postCreateMeyo, postUpdateMeyo } from "../common/Action";
import React, { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { ref, onValue, update } from "firebase/database";
import { firebaseDB } from "../common/firebaseConfig";
import down from "../images/down_b.png";
import back from "../images/arrow-left.png";
import { TYPE_TEXT } from "../common/MeUtils";
import { timeYYMD_MMSS } from "../common/Method";

const ANSWER_FONT_LARGE_LENGTH: number = 6;
const ANSWER_FONT_MEDIUM_LENGTH: number = 20;
const placeholderText: string = "0";

const QUESTION_FONT_LARGE_LENGTH: number = 25;
const QUESTION_FONT_MEDIUM_LENGTH: number = 101;

const MeTextType: React.FC = () => {
  //ref
  const inputRef: React.RefObject<HTMLInputElement>[] = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  const audioRef = useRef<HTMLAudioElement | null>(null); // 오디오 객체 참조

  // const value
  // 버튼 이름 상태값
  const [START] = useState<string>("START");
  const [INIT] = useState<string>("INIT");
  const [ING] = useState<string>("마감하기");
  const [END] = useState<string>("마감됨");

  const history = useHistory();
  const params: any = useParams();

  const [question, setQuestion] = useState<string | undefined>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [students, setStudents] = useState<any>([]);
  const [selectedStudent, setSlelctedStudent] = useState<any>({});
  const [filterSort, setFilterSort] = useState<string[]>([]);
  const [currentTime, setCurrentTime] = useState<string>(""); // 시간버튼 state
  const [currentSortFilter, setCurrentSortFilter] = useState<string>("제출순");
  const currentSortFilterRef = useRef(currentSortFilter);
  const [popupSortFilter, setPopupSortFilter] = useState<boolean>(false);
  const [minFirstDigit, setMinFirstDigit] = useState<string>("");
  const [minSecondDigit, setMinSecondDigit] = useState<string>("");
  const [secFirstDigit, setSecFirstDigit] = useState<string>("");
  const [secSecondDigit, setSecSecondDigit] = useState<string>("");
  const [isRunning, setIsRunning] = useState<boolean>(false); // 타이머 상태
  const [isNoTimerStart, setIsNoTimerStart] = useState<boolean>(false); // 시간제한없음 으로 시작했는지 여부
  const [currentState, setCurrentState] = useState<string>(INIT);
  const [isManualTimeInput, setIsManualTimeInput] = useState<boolean>(false);
  const [pause, setPause] = useState<boolean>(false); // 일시정지
  const [createMeyo, setCreateMeyo] = useState<boolean>(false); // 저요새로만들기 버튼 show
  const [isChocoMode, setIsChocoMode] = useState<boolean>(false); // 초코칩 모드 상태
  const [firebaseData, setFirebaseData] = useState<any>(null); // firebase 리얼타임데이터베이스의 데이터ㅜ
  const [uid, setUid] = useState<string>(""); // firebase 시크릿 uid
  const [createId, setCreateId] = useState<string>(""); // 생성된 저요의 id

  useEffect(() => {
    init();
  }, []);

  // 창 꺼짐 감지
  useEffect(() => {
    // `once: true`를 설정하여 이벤트가 한 번만 실행되도록 함
    // window.addEventListener("beforeunload", handleUnloadEvent, { once: true });
    window.addEventListener("unload", handleUnloadEvent, { once: true });

    return () => {
      // window.removeEventListener("beforeunload", handleUnloadEvent);
      window.removeEventListener("unload", handleUnloadEvent);
    };
  }, [uid]);

  useEffect(() => {
    currentSortFilterRef.current = currentSortFilter;
  }, [currentSortFilter]);

  // fireBase realtime database 파이어베이스 리얼타임 데이터베이스
  useEffect(() => {
    const dbRef = ref(firebaseDB, `meyo/${uid}`); // 데이터를 가져올 경로 설정

    const unsubscribe = onValue(dbRef, (snapshot) => {
      const value = snapshot.val();
      setFirebaseData(value);
      setQuestion(value?.question);

      const studentsKeys = value?.students ? Object.keys(value?.students) : [];
      let tempStudents = Array.isArray(students) ? [...students] : [];

      for (let i = 0; i < studentsKeys.length; i++) {
        for (let k = 0; k < tempStudents.length; k++) {
          if (tempStudents[k]._id.toString() === studentsKeys[i]) {
            tempStudents[k].meyoAnswer = value?.students[studentsKeys[i]].meyoAnswer;
            tempStudents[k].sort = Number(value?.students[studentsKeys[i]].timestamp) || 0;
          }
        }
      }

      setStudents(handleSortStudents(tempStudents));

      if (value.state === "countdown") {
        const seconds = (value.endTimestamp - Date.now()) / 1000;
        const newMinutes = Math.floor(seconds / 60);
        const newSeconds = seconds % 60;
        setMinFirstDigit(Math.floor(newMinutes / 10).toString());
        setMinSecondDigit(Math.floor(newMinutes % 10).toString());
        setSecFirstDigit(Math.floor(newSeconds / 10).toString());
        setSecSecondDigit(Math.floor(newSeconds % 10).toString());
      }
    });

    return () => unsubscribe();
  }, [uid]);

  // 타이머 실행
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (isRunning) {
      timer = setInterval(countDown, 1000); // 1초마다 감소
    } else if (timer) {
      clearInterval(timer); // 타이머 중지
      setCurrentState(END);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isRunning, minFirstDigit, minSecondDigit, secFirstDigit, secSecondDigit]);

  // 진행중인 상태에서 페이지를 벗어나려고 할때를 감지
  useEffect(() => {
    const unblock = history.block(() => {
      if (currentState === ING) {
        const ok = window.confirm("직접 마감하지 않고 나가시겠습니까?\n현재 진행중인 저요는 자동으로 마감됩니다.");
        if (ok) {
          // 마감처리
          handleChangeEnd();
          return undefined; // void 반환
        }

        return false;
      }
      return undefined; // void 반환
    });

    return () => {
      unblock();
    };
  }, [currentState]);

  // 소리 재생 및 중지
  //   useEffect(() => {
  //     if (!audioRef.current) {
  //       audioRef.current = new Audio("https://sellbuymusic.com/md/srjvpth-ifnnthx"); // 소리 파일
  //       audioRef.current.loop = true; // 소리 반복 재생
  //     }

  //     if (isRunning) {
  //       audioRef.current.play(); // 타이머 진행 중일 때 소리 재생
  //     } else {
  //       audioRef.current.pause(); // 타이머 멈출 때 소리 중지
  //     }

  //     return () => {
  //       if (audioRef.current) {
  //         audioRef.current.pause(); // 컴포넌트 언마운트 시 소리 중지
  //       }
  //     };
  //   }, [isRunning]);

  // init 초기화
  const init = async () => {
    // TODO:: 저요 해당 데이터 모두 가져오기 - result- 결과값 meyo -저요 데이터 students - 저요 해당하는 학생들 데이터
    handleSetMin("");
    setCurrentTime("시간제한없음");
    setFilterSort(["번호순", "제출순"]);
    const { result, meyo, students }: any = await getMeyoDetail(params.classId, params.id ? params.id : "students");

    if (!result) {
      // 서버이상 시 빈배열로 초기화
      setStudents([]);
      return;
    }

    setStudents(students);
    setCurrentState(meyo?.isEnd === true ? END : START);

    if (meyo?.length !== 0) {
      setCreateMeyo(true);
      setQuestion(meyo?.question);
      setUid(meyo?.uid);
    }
  };

  // handler
  const handleUnloadEvent = (event: any) => {
    // console.log("페이지를 벗어나려 합니다.");
    // 프론트에서만 강제 마감처리
    handleResetTimer();
    setCurrentTime("");
    setIsNoTimerStart(false);
    setIsRunning(false);
    setCurrentState(END);
    setPause(false);

    // 데이터 준비
    const data = {
      uid: uid, // firebase 시크릿 uid
      _id: createId ? createId : params.id, // 저요의 id
      token: localStorage.getItem("token"), // 보낼 토큰
    };
    // console.log("데이터 전송", data);
    const url = "https://api.dahandin.com";
    // `sendBeacon`을 사용해 한 번만 전송
    navigator.sendBeacon(`${url}/meyo/updatebeacon`, JSON.stringify(data));
    event.returnValue = "창을 종료하시겠습니까?"; // 브라우저 경고 메시지
  };

  const handleChangeEnd = (): void => {
    // 마감처리 하는 함수
    handleUpdateMeyoData("end", true);
    handleResetTimer();
    setCurrentTime("");
    setIsNoTimerStart(false);
    setIsRunning(false);
    setCurrentState(END);
    setPause(false);
  };

  const disabledInput = (): boolean => {
    if (pause) {
      return false;
    } else if (isNoTimerStart) {
      return true;
    } else if (isRunning) {
      return true;
    }
    return false;
  };

  const getTimerMessage = (): string => {
    if (pause) {
      return "저요 가 <일시정지> 되었습니다.";
    }
    if (isRunning) {
      return "진행중에는 타이머 수정이 불가합니다. '마감하기' 후 시간을 재설정 해주세요.";
    }
    if (isNoTimerStart) {
      return "저요 가 시간제한없는 <진행중> 입니다. 시간설정을 원하시면 '마감하기' 후 시간을 설정 해주세요.";
    }
    return "시간은 직접 입력할 수 있습니다.";
  };

  // 학생이 제출할때 정렬하는 함수
  const handleSortStudents = (tempStudents: any) => {
    const tempStudentsCopy = [...tempStudents];
    const currentFilter = currentSortFilterRef.current; // 최신 값 가져오기
    if (currentFilter === "제출순") {
      // .sort 는 타임스탬프 이고 제출하는 순간의 시간 스탬프다
      // 제출시간의 오름차순 정렬 수정 후 제출시간이 변경되면 순차적으로 다시 계산
      tempStudentsCopy.sort((a: any, b: any) => {
        const timeA = a.sort !== undefined && a.sort !== null ? a.sort : Number.MAX_SAFE_INTEGER;
        const timeB = b.sort !== undefined && b.sort !== null ? b.sort : Number.MAX_SAFE_INTEGER;
        return timeA - timeB;
      });

      return tempStudentsCopy;
    }

    if (currentFilter === "번호순") {
      // 번호순으로 학생 정렬하기
      return tempStudentsCopy.sort((a: any, b: any) => (parseInt(a.num) || 0) - (parseInt(b.num) || 0));
    }
  };

  const playIsRunningSound = () => {
    const audio = new Audio("https://artlist.io/sfx/track/clockworks---wooden-clock-ticking-stereo/54613"); // 알람 소리 파일 URL
    audio.play();
  };

  // 저요 생성 create API
  const handleCreateMeyoTextType = async (): Promise<void> => {
    const timeMin: number = isNaN(Number(minFirstDigit + minSecondDigit)) ? 0 : Number(minFirstDigit + minSecondDigit);
    const timeSec: number = isNaN(Number(secFirstDigit + secSecondDigit)) ? 0 : Number(secFirstDigit + secSecondDigit);

    const endTime: number = timeMin * 60000 + timeSec * 1000;

    const data: any = {
      // TODO:: 초코칩
      classId: params.classId, // classId
      question: question ? question : "", // 질문
      startTimestamp: Date.now(), // 서버의 현재 시간으로부터 시작
      endTimestamp: endTime, // 서버의 현재 시간으로부터 종료
      type: TYPE_TEXT, // 유형
      // status: status, // 진행상황에 대한 상태값
    };

    const result: any = await postCreateMeyo(data);
    setCreateId(result.meyoId);
    // console.log("저요 생성", result.meyoId);

    setUid(result.uid);
  };

  // 질문내용 실시간 수정하기 API
  const handleChangeRealTimeQuestion = (value: string) => {
    setQuestion(value);
    if (uid !== "") {
      update(ref(firebaseDB, `meyo/${uid}`), {
        question: value, // 질문 내용 실시간 업데이트
      });
    }
  };

  /**
   * 저요 데이터 수정 API
   * @param {string} state 저요 상태값 (start, ing, end)
   * @param {boolean} isEnd 저요가 종료 상태인지 여부
   * @returns {Promise<void>}
   */
  const handleUpdateMeyoData = async (state: string, isEnd: boolean): Promise<void> => {
    const timeMin: number = isNaN(Number(minFirstDigit + minSecondDigit)) ? 0 : Number(minFirstDigit + minSecondDigit);
    const timeSec: number = isNaN(Number(secFirstDigit + secSecondDigit)) ? 0 : Number(secFirstDigit + secSecondDigit);
    const endTime: number = timeMin * 60000 + timeSec * 1000;

    const result = await postUpdateMeyo({
      _id: params.id ? params.id : createId, // 저요의 id
      uid: uid, // firebase 시크릿 uid
      question: question,
      isEnd: isEnd,
      state: state,
      startTimestamp: Date.now(),
      endTimestamp: endTime,
    });
  };

  const toggleTimer = () => {
    setIsRunning(true);
    setCurrentState(ING);
    // playIsRunningSound();
  };

  const countDown = () => {
    const totalSeconds =
      Number(minFirstDigit) * 600 + Number(minSecondDigit) * 60 + Number(secFirstDigit) * 10 + Number(secSecondDigit);

    if (totalSeconds > 0) {
      const newTotalSeconds = totalSeconds - 1;
      const newMinutes = Math.floor(newTotalSeconds / 60);
      const newSeconds = newTotalSeconds % 60;

      setMinFirstDigit(Math.floor(newMinutes / 10).toString());
      setMinSecondDigit((newMinutes % 10).toString());
      setSecFirstDigit(Math.floor(newSeconds / 10).toString());
      setSecSecondDigit((newSeconds % 10).toString());
    } else {
      // 카운트다운 종료
      handleUpdateMeyoData("end", true);
      setCurrentState(END);
      setIsRunning(false); // 타이머 멈춤
      setIsNoTimerStart(false);
      setPause(false);
      setCurrentTime("");
    }
  };

  // 필터 정렬 번호순 제출순
  const handleSortFilterSelect = (index: number) => {
    const tempStudents = [...students];

    if (filterSort[index] === "번호순") {
      const sortedStudents = tempStudents.sort((a, b) => (parseInt(a.num) || 0) - (parseInt(b.num) || 0));
      setStudents(sortedStudents);
    } else {
      // 제출시간에 따른 오름차순 정렬 -> 가장 이전에 제출한 학생이 앞으로, 수정하여 제출시간이 커지면 뒤로, 제출시간이 존재하지않는 큰 수를 할당하여 맨 뒤로
      tempStudents.sort((a: any, b: any) => {
        const timeA = a.sort !== undefined && a.sort !== null ? a.sort : Number.MAX_SAFE_INTEGER;
        const timeB = b.sort !== undefined && b.sort !== null ? b.sort : Number.MAX_SAFE_INTEGER;
        return timeA - timeB;
      });

      setStudents(tempStudents);
    }
    setPopupSortFilter(false);
    setCurrentSortFilter(filterSort[index]);
  };

  // 타이머 input 자릿수들 빈문자열로 초기화
  const handleResetTimer = () => {
    setMinFirstDigit("");
    setMinSecondDigit("");
    setSecFirstDigit("");
    setSecSecondDigit("");
  };

  // 시간설정을 했는지 여부
  const haveSetATime = () => {
    if (
      (minFirstDigit === "" && minSecondDigit === "" && secFirstDigit === "" && secSecondDigit === "") ||
      (minFirstDigit === "0" && minSecondDigit === "0" && secFirstDigit === "0" && secSecondDigit === "0")
    ) {
      return false;
    }
    return true;
  };

  const handleSetMin = (min: string) => {
    setCurrentTime(min);
    setIsManualTimeInput(false);

    // 10초", "15초", "30초", "1분", "2분", "3분", "5분", "10분", "시간제한없음
    switch (min) {
      case "시간제한없음":
        setMinFirstDigit("");
        setMinSecondDigit("");
        setSecFirstDigit("");
        setSecSecondDigit("");
        break;

      case "10초":
        setMinFirstDigit("0");
        setMinSecondDigit("0");
        setSecFirstDigit("1");
        setSecSecondDigit("0");
        break;

      case "15초":
        setMinFirstDigit("0");
        setMinSecondDigit("0");
        setSecFirstDigit("1");
        setSecSecondDigit("5");
        break;

      case "30초":
        setMinFirstDigit("0");
        setMinSecondDigit("0");
        setSecFirstDigit("3");
        setSecSecondDigit("0");
        break;

      case "1분":
        setMinFirstDigit("0");
        setMinSecondDigit("1");
        setSecFirstDigit("0");
        setSecSecondDigit("0");
        break;

      case "2분":
        setMinFirstDigit("0");
        setMinSecondDigit("2");
        setSecFirstDigit("0");
        setSecSecondDigit("0");
        break;

      case "3분":
        setMinFirstDigit("0");
        setMinSecondDigit("3");
        setSecFirstDigit("0");
        setSecSecondDigit("0");
        break;

      case "5분":
        setMinFirstDigit("0");
        setMinSecondDigit("5");
        setSecFirstDigit("0");
        setSecSecondDigit("0");
        break;

      case "10분":
        setMinFirstDigit("1");
        setMinSecondDigit("0");
        setSecFirstDigit("0");
        setSecSecondDigit("0");
        break;

      default:
        break;
    }
  };

  const handleDigitChange = (
    value: string,
    setter: Dispatch<SetStateAction<string>>,
    nextRef?: RefObject<HTMLInputElement>,
  ) => {
    if (value.length <= 1 && Number(value) >= 0) {
      setter(value);
      setIsManualTimeInput(true);
      setCurrentTime("");
      if (value.length === 1 && nextRef?.current) {
        nextRef.current.focus();
      }
    }
  };

  const handleBackspace = (
    currentValue: string,
    currentSetter: Dispatch<SetStateAction<string>>,
    prevValue: string,
    prevSetter: Dispatch<SetStateAction<string>>,
    currentRef: RefObject<HTMLInputElement>,
    prevRef: RefObject<HTMLInputElement>,
  ) => {
    if (currentValue.length === 0 || currentRef.current?.selectionStart === 0) {
      if (prevValue.length > 0) {
        prevSetter("");
        prevRef.current?.focus();
        // 이전 input의 커서를 마지막으로 이동
        setTimeout(() => {
          if (prevRef.current) {
            prevRef.current.selectionStart = prevRef.current.value.length;
            prevRef.current.selectionEnd = prevRef.current.value.length;
          }
        }, 0);
      }
    } else {
      currentSetter("");
    }
    setIsManualTimeInput(true);
    setCurrentTime("");
  };

  const handleGoBack = async () => {
    if (currentState === ING) {
      alert("현재 진행중인 '저요' 를 마감해 주세요!");
    } else {
      // 뒤로가기
      await handleUpdateMeyoData("end", true);
      history.goBack();
      // window.location.href = `/page/me/${params.classId}`;
    }
  };

  // 버튼 배경색 상태에 따라 변경
  const getStateColor = (state: string): string => {
    switch (state) {
      case START:
        return "bg-[#539669] border-[#347a4e]";
      case ING:
        return "bg-[#d62d20] border-[#801b13]";
      case END:
        return "bg-[#eeeeee]";
      default:
        break;
    }
    return "";
  };

  // 모달 열기 핸들러 추가
  const handleOpenModal = (studentData: any) => {
    setIsModalOpen(true);
    setSlelctedStudent(studentData);
  };

  return (
    <div className={"tailwind-styles"}>
      {/* <Prompt
        when={currentState === ING}
        message={`직접 마감하지 않고 나가시겠습니까?\n현재 진행중인 저요는 자동으로 마감됩니다.`}
      /> */}
      <div className={"p-8"}>
        <div
          className="mt-40 flex h-[140px]"
          style={
            {
              // backgroundColor: "pink"
            }
          }
        >
          <div
            className="flex basis-1/4 h-[80px] items-center"
            // style={{ backgroundColor: "gold" }}
          >
            <img
              onClick={handleGoBack}
              src={back}
              className="mr-16 cursor"
              style={{ marginLeft: -10, width: 45, height: "auto" }}
            />
            <div>
              <p className={"text-5xl font-medium"}>텍스트형</p>
              <p className={"text-4xl font-normal antialiased mt-2"}>새로만들기</p>
            </div>
          </div>

          {/* 시간선택 시간설정 영역 */}
          <div
            className="flex basis-2/4"
            style={{
              justifyContent: "center",
              // backgroundColor: "skyblue",
            }}
          >
            {/* 시간 입력 input */}
            <div
              className="justify-items-center"
              // style={{ backgroundColor: "green" }}
            >
              <div className="flex items-center">
                {/* 일시정지 버튼 */}
                <button
                  className={`w-[170px] h-[80px] border border-4 rounded-lg bg-[#eeeeee] mr-[10px]
                    ${isRunning ? "text-[#222222]" : "text-[#bcbcbc]"}
                    `}
                  onClick={() => {
                    if (isRunning) {
                      // 일시정지
                      handleUpdateMeyoData("pause", false);
                      setPause(true);
                      setIsRunning(false);
                    }
                  }}
                >
                  <h2 className="text-4xl font-medium text-center">일시정지</h2>
                </button>

                {/* 타이머 */}
                <div
                  className={`flex w-[400px] h-[80px] justify-center space-x-2 pl-20 pr-20 pb-3 border border-4 rounded-lg
                  ${pause || isRunning || isNoTimerStart ? "border-[#d62d20]" : "border-[#eeeeee]"}
                   ${isRunning || isNoTimerStart ? "bg-[#eeeeee]" : "bg-white"}
                   ${pause && "bg-white"}
                  `}
                >
                  <div>
                    <input
                      ref={inputRef[0]}
                      disabled={disabledInput()}
                      value={minFirstDigit || ""}
                      autoCapitalize="off"
                      accept="number"
                      placeholder={placeholderText} // Placeholder와 동기화}
                      step={"1"}
                      // style={{ lineHeight: "100%" }}
                      className={`w-[50px] h-full text-center focus:outline-none text-8xl placeholder-[#bcbcbc] 
                        ${minFirstDigit ? "font-extrabold" : "placeholder::font-normal"}
                        ${isRunning ? "text-[#d62d20]" : "text-black"}
                        `}
                      onChange={(e) => {
                        handleDigitChange(e.target.value, setMinFirstDigit, inputRef[1]);
                      }}
                      onKeyDown={(e) => {
                        // 숫자 키만 허용 (백스페이스, Delete, 화살표 제외)
                        if (
                          !["Backspace", "ArrowLeft", "ArrowRight", "Delete"].includes(e.key) &&
                          !/^[0-9]$/.test(e.key)
                        ) {
                          e.preventDefault();
                        }
                        // 스페이스바 차단
                        if (e.key === " " || e.key === "Space") {
                          e.preventDefault();
                        }

                        if (e.key === "Backspace") {
                          e.preventDefault();
                          handleBackspace(
                            minFirstDigit,
                            setMinFirstDigit,
                            "",
                            setMinFirstDigit,
                            inputRef[0],
                            inputRef[0],
                          );
                        }

                        if (e.key === "ArrowRight" && inputRef[0].current?.selectionStart === 1) {
                          inputRef[1].current?.focus();
                        }
                      }}
                    />
                    <input
                      ref={inputRef[1]}
                      disabled={disabledInput()}
                      value={minSecondDigit || ""}
                      autoCapitalize="off"
                      accept="number"
                      placeholder="0"
                      step={"1"}
                      className={`w-[50px] h-full text-center focus:outline-none text-8xl placeholder-[#bcbcbc] 
                        ${minSecondDigit ? "font-extrabold" : "placeholder::font-normal"}
                        ${isRunning ? "text-[#d62d20]" : "text-black"}
                        `}
                      onChange={(e) => {
                        handleDigitChange(e.target.value, setMinSecondDigit, inputRef[2]);
                      }}
                      onKeyDown={(e) => {
                        // 숫자 키만 허용 (백스페이스, Delete, 화살표 제외)
                        if (
                          !["Backspace", "ArrowLeft", "ArrowRight", "Delete"].includes(e.key) &&
                          !/^[0-9]$/.test(e.key)
                        ) {
                          e.preventDefault();
                        }
                        // 스페이스바 차단
                        if (e.key === " " || e.key === "Space") {
                          e.preventDefault();
                        }

                        if (e.key === "Backspace") {
                          e.preventDefault();
                          handleBackspace(
                            minSecondDigit,
                            setMinSecondDigit,
                            minFirstDigit,
                            setMinFirstDigit,
                            inputRef[1],
                            inputRef[0],
                          );
                        }

                        if (e.key === "ArrowLeft" && inputRef[1].current?.selectionStart === 0) {
                          inputRef[0].current?.focus();
                        } else if (e.key === "ArrowRight" && inputRef[1].current?.selectionStart === 1) {
                          inputRef[2].current?.focus();
                        }
                      }}
                    />
                  </div>

                  <div
                    className="h-full"
                    style={{
                      alignContent: "center",
                      // backgroundColor: "red"
                    }}
                  >
                    <span
                      className={`text-8xl
                      ${isRunning ? "text-[#d62d20]" : "text-black"}
                      `}
                    >
                      ：
                    </span>
                  </div>

                  <div className="flex items-center">
                    <input
                      ref={inputRef[2]}
                      // disabled={isRunning || isNoTimerStart}
                      disabled={disabledInput()}
                      value={secFirstDigit || ""}
                      autoCapitalize="off"
                      accept="number"
                      placeholder="0"
                      step={"1"}
                      className={`w-[50px] h-full text-center focus:outline-none text-8xl placeholder-[#bcbcbc] 
                        ${secFirstDigit ? "font-extrabold" : "placeholder::font-normal"}
                        ${isRunning ? "text-[#d62d20]" : "text-black"}
                        `}
                      onChange={(e) => {
                        handleDigitChange(e.target.value, setSecFirstDigit, inputRef[3]);
                      }}
                      onKeyDown={(e) => {
                        // 숫자 키만 허용 (백스페이스, Delete, 화살표 제외)
                        if (
                          !["Backspace", "ArrowLeft", "ArrowRight", "Delete"].includes(e.key) &&
                          !/^[0-9]$/.test(e.key)
                        ) {
                          e.preventDefault();
                        }
                        // 스페이스바 차단
                        if (e.key === " " || e.key === "Space") {
                          e.preventDefault();
                        }

                        if (e.key === "Backspace") {
                          e.preventDefault();
                          handleBackspace(
                            secFirstDigit,
                            setSecFirstDigit,
                            minSecondDigit,
                            setMinSecondDigit,
                            inputRef[2],
                            inputRef[1],
                          );
                        }

                        if (e.key === "ArrowLeft" && inputRef[2].current?.selectionStart === 0) {
                          inputRef[1].current?.focus();
                        } else if (e.key === "ArrowRight" && inputRef[2].current?.selectionStart === 1) {
                          inputRef[3].current?.focus();
                        }
                      }}
                    />
                    <input
                      ref={inputRef[3]}
                      disabled={disabledInput()}
                      value={secSecondDigit || ""}
                      autoCapitalize="off"
                      accept="number"
                      placeholder="0"
                      step={"1"}
                      className={`w-[50px] h-full text-center focus:outline-none text-8xl placeholder-[#bcbcbc] 
                        ${secSecondDigit ? "font-extrabold" : "placeholder::font-normal"}
                        ${isRunning ? "text-[#d62d20]" : "text-black"}
                        `}
                      onChange={(e) => {
                        const value = e.target.value;
                        let numberSec = Number(minFirstDigit + secSecondDigit);
                        let numberMin = Number(minFirstDigit + minSecondDigit);

                        handleDigitChange(value, setSecSecondDigit);

                        if (numberMin == 99 && Number(minFirstDigit) == 9 && Number(value) == 9) {
                          setSecFirstDigit("5");
                        }
                      }}
                      onKeyDown={(e) => {
                        // 숫자 키만 허용 (백스페이스, Delete, 화살표 제외)
                        if (
                          !["Backspace", "ArrowLeft", "ArrowRight", "Delete"].includes(e.key) &&
                          !/^[0-9]$/.test(e.key)
                        ) {
                          e.preventDefault();
                        }
                        // 스페이스바 차단
                        if (e.key === " " || e.key === "Space") {
                          e.preventDefault();
                        }

                        if (e.key === "Backspace") {
                          e.preventDefault();
                          handleBackspace(
                            secSecondDigit,
                            setSecSecondDigit,
                            secFirstDigit,
                            setSecFirstDigit,
                            inputRef[3],
                            inputRef[2],
                          );
                        }

                        if (e.key === "ArrowLeft" && inputRef[3].current?.selectionStart === 0) {
                          inputRef[2].current?.focus();
                        }
                      }}
                    />
                  </div>
                </div>

                {/* 실제 타이머 렌더링 카운트다운 */}
                {/* <div
                  className={`flex w-[400px] h-[80px] justify-center space-x-2 pl-20 pr-20 pb-3 border border-4 rounded-lg
                  ${pause || isRunning || isNoTimerStart ? "border-[#d62d20]" : "border-[#eeeeee]"}
                   ${isRunning || isNoTimerStart ? "bg-[#eeeeee]" : "bg-white"}
                   ${pause && "bg-white"}
                  `}
                >
                  <p
                    className={`w-[50px] h-full text-center focus:outline-none text-8xl placeholder-[#bcbcbc] line-space-4 
                        ${secFirstDigit ? "font-extrabold" : "placeholder::font-normal"}
                        ${isRunning ? "text-[#d62d20]" : "text-black"}
                        `}
                  >
                    {formatTime(serverTimer).slice(
                      formatTime(serverTimer).length - 4,
                      formatTime(serverTimer).length - 3,
                    )}
                  </p>
                  <p
                    className={`w-[50px] h-full text-center focus:outline-none text-8xl placeholder-[#bcbcbc] line-space-4 
                        ${secFirstDigit ? "font-extrabold" : "placeholder::font-normal"}
                        ${isRunning ? "text-[#d62d20]" : "text-black"}
                        `}
                  >
                    {formatTime(serverTimer).slice(
                      formatTime(serverTimer).length - 3,
                      formatTime(serverTimer).length - 2,
                    )}
                  </p>
                  <div
                    className="h-full"
                    style={{
                      alignContent: "center",
                      // backgroundColor: "red"
                    }}
                  >
                    <span
                      className={`text-8xl
                      ${isRunning ? "text-[#d62d20]" : "text-black"}
                      `}
                    >
                      ：
                    </span>
                  </div>
                  <p
                    className={`w-[50px] h-full text-center focus:outline-none text-8xl placeholder-[#bcbcbc] line-space-4 
                        ${secFirstDigit ? "font-extrabold" : "placeholder::font-normal"}
                        ${isRunning ? "text-[#d62d20]" : "text-black"}
                        `}
                  >
                    {formatTime(serverTimer).slice(
                      formatTime(serverTimer).length - 2,
                      formatTime(serverTimer).length - 1,
                    )}
                  </p>
                  <p
                    className={`w-[50px] h-full text-center focus:outline-none text-8xl placeholder-[#bcbcbc] line-space-4 
                        ${secFirstDigit ? "font-extrabold" : "placeholder::font-normal"}
                        ${isRunning ? "text-[#d62d20]" : "text-black"}
                        `}
                  >
                    {formatTime(serverTimer).slice(formatTime(serverTimer).length - 1, formatTime(serverTimer).length)}
                  </p>
                </div> */}
                {/* 실제 타이머 렌더링 카운트다운 */}
              </div>

              <p
                className={`text-3xl text-center mt-9 ${
                  pause || isRunning || isNoTimerStart ? "text-[#d62d20]" : "text-[#999999]"
                } whitespace-nowrap`}
              >
                {getTimerMessage()}
              </p>
            </div>
          </div>

          {/* 버튼 */}
          <div
            className={"flex basis-1/4"}
            style={{
              justifyContent: "end",
              // backgroundColor: "blue",
            }}
          >
            {/* 다시 이어서 하기 버튼 */}
            {currentState === END || pause ? (
              <button
                className={
                  "w-[200px] h-[80px] flex border border-4 rounded-lg justify-center items-center mr-2 bg-[#539669] border-[#347a4e]"
                }
                onClick={() => {
                  if (currentTime === "" && haveSetATime() === false) {
                    alert("<타이머>를 설정하거나 <시간제한없음>을 선택해주세요!");
                    return;
                  } else if (haveSetATime()) {
                    // 타이머 설정 후 시작
                    // 파이어베이스 firebase realtime 시작하기 상태 업데이트 및 저장
                    handleUpdateMeyoData("countdown", false);
                    setPause(false);
                    toggleTimer();
                  } else {
                    // 시간제한없음 시작
                    // 파이어베이스 firebase realtime 시작하기 상태 업데이트 및 저장
                    handleUpdateMeyoData("ing", false);
                    setPause(false);
                    setIsNoTimerStart(true);
                    setCurrentState(ING);
                  }
                }}
              >
                <div className="text-center">
                  <h2 className="text-4xl font-semibold mb-2 text-[#fff]">다시(이어서)</h2>
                  <h2 className="text-4xl font-semibold mb-2 text-[#fff]">하기</h2>
                </div>
              </button>
            ) : (
              <div className={"w-[220px] h-[100px] mr-2"} />
            )}

            {/* 시작 버튼 스타트 버튼 시작버튼 마감버튼 마감하기 마감 */}
            <div
              className={`${
                currentState === INIT ? "hidden" : ""
              } w-[170px] h-[80px] border-4 rounded-lg flex items-center justify-center ml-2 
                ${createMeyo === false ? "text-[#cccccc] bg-[#eeeeee] border-[#cccccc]" : "text-[#fff]"}
                ${
                  currentState === END
                    ? ""
                    : "cursor-pointer active:scale-95 transition-transform duration-150 ease-in-out"
                }  
                ${getStateColor(currentState)}
                `}
              onClick={() => {
                // 다양한 조건에 대한 중복 검증을 피하기 위해 상호베타적으로 코드 구현
                // 1. 기본 검증
                if (currentState !== END && !createMeyo) {
                  alert("먼저 저요를 생성해주세요!");
                  return;
                }

                // 2. 시간 설정 검증
                if (currentState !== END && currentTime === "" && !haveSetATime()) {
                  alert("<타이머>를 설정하거나 <시간제한없음>을 선택해주세요!");
                  return;
                }

                // 5. 진행중인 저요 마감 (이 조건을 먼저 체크)
                if (currentState === ING) {
                  handleChangeEnd();
                  return; // 여기서 return을 추가하여 다른 조건문 실행 방지
                }

                // 3. 타이머 설정된 경우
                if (currentState !== END && haveSetATime()) {
                  handleUpdateMeyoData("countdown", false);
                  toggleTimer();
                  return;
                }

                // 4. 시간제한없음 시작
                if (currentState !== END && !haveSetATime()) {
                  handleUpdateMeyoData("ing", false);
                  setPause(false);
                  setIsNoTimerStart(true);
                  setCurrentState(ING);
                }
              }}
            >
              <p className={`text-4xl font-semibold mb-2 ${currentState === END && "text-[#d62d20] text-[#fff]"}`}>
                {currentState}
              </p>
            </div>
          </div>
        </div>

        {/* 시간버튼 영역 */}
        <div className="flex flex-wrap gap-4 mt-[30px]">
          {["10초", "15초", "30초", "1분", "2분", "3분", "5분", "10분", "시간제한없음"].map((time, index) => (
            <button
              key={index}
              onClick={() => {
                if (isRunning === false) {
                  handleSetMin(time);
                }
              }}
              disabled={disabledInput()}
              className={`px-8 py-4 border text-3xl border-2 rounded-lg
              ${
                currentTime === time
                  ? "border-[#347a4e]"
                  : pause
                  ? "border-[#999999]"
                  : isRunning || isNoTimerStart
                  ? "border-[#cccccc]"
                  : "border-[#999999]"
              }  
              ${
                currentTime === time
                  ? "text-white"
                  : pause
                  ? "text-[#5b5b5b]"
                  : isRunning || isNoTimerStart
                  ? "text-[#cccccc]"
                  : "text-[#5b5b5b]"
              }
              ${currentTime === time ? "bg-[#539669]" : "bg-[#fff]"} 
            
              `}
            >
              {time}
            </button>
          ))}
        </div>

        {/* 질문입력 */}
        <div className="flex-full mt-20 flex items-center justify-center space-x-5">
          <textarea
            value={question || ""}
            onChange={(e) => handleChangeRealTimeQuestion(e.target.value)}
            placeholder="질문을 입력해주세요. (최대 150자)"
            maxLength={150}
            className={`w-full h-[150px] border border-[#5b5b5b] rounded-lg focus:outline-none py-12 px-12 resize-none break-words whitespace-pre-wrap overflow-x-hidden flex items-center text-center
              ${
                (question?.length || 0) <= QUESTION_FONT_LARGE_LENGTH
                  ? "text-7xl placeholder:mt-[20px]"
                  : (question?.length || 0) <= QUESTION_FONT_MEDIUM_LENGTH
                  ? "text-5xl placeholder:mt-[25px]"
                  : "text-4xl placeholder:mt-[30px]"
              }
            `}
          />
        </div>

        {createMeyo === false && (
          <button
            className={"mt-[20px] w-full h-[60px] border border-4 rounded-lg bg-[#539669] border-[#347a4e]"}
            onClick={() => {
              // TODO: 새로운 저요 생성 로직 구현
              setCreateMeyo(true);
              handleCreateMeyoTextType();
            }}
          >
            <h2 className="text-4xl font-medium text-center text-white">{`생성`}</h2>
          </button>
        )}

        {/* 초코칩 정렬 필터 번호순 제출순 */}
        <div className="flex w-full mt-[40px]">
          {/* 초코칩 버튼 - 왼쪽 끝 */}
          {/* {!isChocoMode && (
            <div className="flex-1">
              <button
                onClick={() => setIsChocoMode(true)}
                className={`h-[41px] w-[150px] rounded-lg text-white text-center text-3xl ${
                  isChocoMode ? "bg-[#444444]" : "bg-[#693012]"
                }`}
              >
                초코칩
              </button>
            </div>
          )} */}

          {/* 초코칩 활성화 && 저장 취소 버튼 */}
          {/* {isChocoMode && (
            <div className="flex">
              <div className="flex-1 z-50">
                <button
                  onClick={() => {
                    // 저장 로직 추가
                    setIsChocoMode(false);
                  }}
                  className=" h-[41px] w-[100px] bg-white rounded-lg text-[#693012] text-center text-3xl mr-4 z-50"
                >
                  저장
                </button>
              </div>
              <div className="flex-1 z-50">
                <button
                  onClick={() => setIsChocoMode(false)}
                  className="h-[41px] w-[100px] bg-[#693012] rounded-lg text-white text-center text-3xl mr-12 z-50"
                >
                  취소
                </button>
              </div>
            </div>
          )} */}
          {/* 필터버튼 번호순 제출순 - 오른쪽 끝 */}
          <div className="flex ml-auto">
            <button
              onClick={() => setPopupSortFilter((prev) => !prev)}
              className="filter_btn text-left pl-17 pr-12 display-f justify-between align-center"
              style={{
                opacity: 100,
                transition: "all 0.3s ease-in-out", // 부드러운 전환 효과
              }}
            >
              {currentSortFilter} <img className="filter_icon" src={down} alt="down" />
            </button>
          </div>

          <div className="relative">
            {popupSortFilter === true && (
              <div className="absolute filter_contents bg-white" style={{ top: 40, right: 4 }}>
                {filterSort.map((data: string, index: number) => (
                  <div onClick={() => handleSortFilterSelect(index)} className="filter_inner cursor" key={index}>
                    <p className="pl-17 pt-11 pb-11 font-14">{data}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {/* 정렬 */}

        {/* 회색 오버레이 */}
        {isChocoMode && <div className="fixed inset-0 bg-black bg-opacity-50 z-40" style={{ pointerEvents: "none" }} />}

        {/* 학생카드 */}
        <div
          // 반응형
          className="w-full grid grid-cols-[repeat(auto-fill,251px)] gap-8 mt-20 mb-[100px] justify-center relative"
        >
          {students?.map((data: any, index: number) => (
            <div
              key={data.id || index}
              onClick={() => {
                handleOpenModal(data);
              }}
              className={`h-[180px] w-[251px] rounded-lg pt-6 pr-6 pl-6 pb-[24px] overflow-hidden cursor-pointer relative ${
                data?.meyoAnswer ? "bg-[#eeeeee]" : "bg-[rgba(255,165,0,0.5)]"
              }`}
            >
              <div className="flex flex-row items-center justify-between">
                <p className={"text-4xl font-medium"}>{data.name}</p>
                {/* 초코칩 자리 */}
                {/* <p className={"text-4xl font-medium"}>{3}</p> */}
              </div>

              <p
                className={`leading-normal break-words text-overflow ellipsis mt-10 ${
                  !data?.meyoAnswer
                    ? "text-3xl"
                    : data.meyoAnswer.length <= ANSWER_FONT_LARGE_LENGTH
                    ? "text-center text-7xl"
                    : data.meyoAnswer.length <= ANSWER_FONT_MEDIUM_LENGTH
                    ? "text-center text-5xl"
                    : "text-3xl"
                }`}
              >
                {data?.meyoAnswer}
              </p>

              {/* 우측 하단 정렬 */}
              <p className="absolute bottom-2 right-4 text-gray-400 text-normal">
                {data.sort && timeYYMD_MMSS(data.sort)}
              </p>
            </div>
          ))}
        </div>

        {/* 모달 학생응답 */}
        {isModalOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={() => setIsModalOpen(false)}
          >
            <div
              className="bg-white rounded-[30px] px-14 py-10 w-[30%] h-[55%] relative"
              onClick={(e) => e.stopPropagation()} // 모달 내부 클릭 시 이벤트 전파 방지
            >
              {/* 닫기 버튼 */}
              <button
                onClick={() => setIsModalOpen(false)}
                className="absolute top-8 right-8 text-6xl hover:text-gray-700 cursor-pointer font-bold"
              >
                ×
              </button>

              {/* 학생 이름 */}
              <h2 className="text-5xl font-bold mb-6 text-[#000] ]">{selectedStudent.name}</h2>
              <h2 className="text-normal mb-6 text-gray-400">
                {selectedStudent.sort && timeYYMD_MMSS(selectedStudent.sort)}
              </h2>

              {/* 답변 내용 */}
              <div className="overflow-y-auto h-[calc(100%-100px)]">
                <p
                  className={`whitespace-pre-wrap leading-normal mt-10 ${
                    !selectedStudent?.meyoAnswer
                      ? "text-2xl"
                      : selectedStudent.meyoAnswer.length <= ANSWER_FONT_LARGE_LENGTH
                      ? "text-7xl"
                      : selectedStudent.meyoAnswer.length <= ANSWER_FONT_MEDIUM_LENGTH
                      ? "text-5xl"
                      : "text-3xl"
                  }`}
                >
                  {selectedStudent.meyoAnswer}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MeTextType;
