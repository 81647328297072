// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyANlvqYD1GbLlfNbTd6HaN4EsoOMdrZVok",
  authDomain: "dahatni-push-notification-v2.firebaseapp.com",
  databaseURL: "https://dahatni-push-notification-v2-default-rtdb.firebaseio.com",
  projectId: "dahatni-push-notification-v2",
  storageBucket: "dahatni-push-notification-v2.firebasestorage.app",
  messagingSenderId: "753816280826",
  appId: "1:753816280826:web:d88874f187a41097623343",
  measurementId: "G-8TD3KBCEF6",
};

// Firebase 초기화
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Realtime Database 가져오기
export const firebaseDB = getDatabase(app);
